import React from "react";
import kubeLogo from "../images/logo.png";
import { Navbar, Nav } from "react-bootstrap";

const NavigationBar: React.FC = () => {
  return (
    <Navbar
      className="justify-content-between color-white"
      expand="lg"
      style={{
        padding: "20px",
        fontFamily: "Inter, sans-serif",
        fontWeight: "600",
      }}
    >
      <Navbar.Brand href="https://kubefinance.com">
        <img
          style={{
            height: "25px",
            width: "110px",
            objectFit: "cover",
          }}
          loading="eager"
          alt=""
          src={kubeLogo}
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{
          background: "white",
          textDecoration: "none",
          transition: "border-color 0.3s",
        }}
      />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-end"
        style={{
          color: "white",
          textDecoration: "none",
          transition: "border-color 0.3s",
        }}
      >
        <Nav className="mt-auto">
          <Nav.Link
            href="https://invoice.kubefinance.com/"
            style={{
              marginRight: "55px",
              color: "white",
              textDecoration: "none",
              transition: "border-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.borderColor = "white";
              e.currentTarget.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.borderColor = "transparent";
              e.currentTarget.style.textDecoration = "none";
            }}
          >
            Invoice AI
          </Nav.Link>
          <Nav.Link
            href="https://www.kubefinance.com/#features"
            style={{
              marginRight: "55px",
              color: "white",
              textDecoration: "none",
              transition: "border-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.borderColor = "white";
              e.currentTarget.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.borderColor = "transparent";
              e.currentTarget.style.textDecoration = "none";
            }}
          >
            Features
          </Nav.Link>
          <Nav.Link
            href="https://www.kubefinance.com/blog"
            style={{
              marginRight: "55px",
              color: "white",
              textDecoration: "none",
              transition: "border-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.borderColor = "white";
              e.currentTarget.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.borderColor = "transparent";
              e.currentTarget.style.textDecoration = "none";
            }}
          >
            Blog
          </Nav.Link>
          <Nav.Link
            href="https://www.kubefinance.com/#:h3IT8wrvF"
            style={{
              marginRight: "55px",
              color: "white",
              textDecoration: "none",
              transition: "border-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.borderColor = "white";
              e.currentTarget.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.borderColor = "transparent";
              e.currentTarget.style.textDecoration = "none";
            }}
          >
            Get in touch
          </Nav.Link>
          <Nav.Link
            href="https://fqq61bfocr9.typeform.com/to/IbpiipBs?typeform-source=www.kubefinance.com"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              cursor: "pointer",
              padding: "8px 18px",
              color: "black",
              borderRadius: "12px",
              background: "white",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
              textDecoration: "none",
              position: "relative",
              fontSize: "15px",
              lineHeight: "100%",
              fontWeight: "600",
              fontFamily: "Inter",
              textAlign: "center",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "white";
              e.currentTarget.style.background = "black";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "black";
              e.currentTarget.style.background = "white";
            }}
          >
            Sign up for free
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
