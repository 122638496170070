import React, { FC, ReactNode } from "react";
import { View as PdfView } from "@react-pdf/renderer";
import compose from "../styles/compose";

interface Props {
  className?: string;
  pdfMode?: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
}

const View: FC<Props> = ({ className, pdfMode, children, style }) => {
  // Initialize combinedStyle with an empty object if style prop is not provided
  let combinedStyle = {};

  // If pdfMode is true, merge styles from compose and style prop
  if (pdfMode) {
    combinedStyle = {
      ...compose("view " + (className ? className : "")),
      ...(style || {}),
    };
  } else {
    // Otherwise, use style prop directly if provided
    combinedStyle = style || {};
  }

  return (
    <>
      {pdfMode ? (
        <PdfView style={combinedStyle}>{children}</PdfView>
      ) : (
        <div
          className={"view " + (className ? className : "")}
          style={combinedStyle}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default View;
