import { Product, Invoice } from './types'

export const initialProduct: Product = {
  name: '',
  description: '',
  quantity: '1',
  rate: '0.00',
}

export const initialInvoice: Invoice = {
  logo: '',
  logoWidth: 100,
  title: 'INVOICE',
  companyName: '',
  employeeName: '',
  companyAddressLine1: '',
  companyAddressLine2: '',
  companyCountry: 'United Kingdom',
  clientCompanyName: '',
  clientCompanyAddressLine1: '',
  clientCompanyAddressLine2: '',
  clientCompanyCountry: 'United Kingdom',
  invoiceNumberLabel: 'Invoice #',
  invoiceNumber: '',
  referenceLabel: 'Reference',
  referenceNumber: '',
  invoiceDateLabel: 'Invoice Date',
  invoiceDate: '',
  invoiceDueDateLabel: 'Due Date',
  invoiceDueDate: '',
  productDescriptionTitle: 'Service',
  productQuantityTitle: 'Qty',
  productRateTitle: 'Rate',
  productAmountTitle: 'Amount',
  products: [
    {
      name: 'Name',
      description: 'Description',
      quantity: '1',
      rate: '0.00',
    },
    { ...initialProduct },
  ],
  notes: "",
  subTotalLabel: 'Subtotal',
  taxLabel: 'VAT (10%)',
  shippingCostsLabel: 'Shipping Costs',
  shippingCosts: '0.00',
  totalLabel: 'Total Due',
  currency: '£',
}