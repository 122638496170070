import React, { createContext, useContext } from "react";
import OpenAI from "openai";

interface OpenAIContextType {
  openai: OpenAI;
}

const OpenAIContext = createContext<OpenAIContextType | null>(null);

export const useOpenAI = () => {
  const context = useContext(OpenAIContext);
  if (!context) {
    throw new Error("useOpenAI must be used within an OpenAIProvider");
  }
  return context;
};

export const OpenAIProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPEN_AI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  return (
    <OpenAIContext.Provider value={{ openai }}>
      {children}
    </OpenAIContext.Provider>
  );
};
