import React, { FC, useState, useEffect } from "react";
import { Invoice, Product } from "../data/types";
import { initialInvoice, initialProduct } from "../data/initialData";
import EditableInput from "./EditableInput";
import EditableSelect from "./EditableSelect";
import EditableTextarea from "./EditableTextarea";
import EditableCalendarInput from "./EditableCalendarInput";
import EditableFileImage from "./EditableFileImage";
import countryList from "../data/countryList";
import Document from "./Document";
import Page from "./Page";
import View from "./View";
import Text from "./Text";
import { Font } from "@react-pdf/renderer";
import Download from "./DownloadPDF";
import format from "date-fns/format";

Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 600,
    },
  ],
});

interface Props {
  data?: Invoice;
  pdfMode?: boolean;
}

const InvoicePage: FC<Props> = ({ data, pdfMode }) => {
  const [invoice, setInvoice] = useState<Invoice>(
    data ? { ...data } : { ...initialInvoice }
  );
  const [subTotal, setSubTotal] = useState<number>();
  const [saleTax, setSaleTax] = useState<number>();

  const dateFormat = "MMM dd, yyyy";
  const invoiceDate =
    invoice.invoiceDate !== "" ? new Date(invoice.invoiceDate) : new Date();
  const invoiceDueDate =
    invoice.invoiceDueDate !== ""
      ? new Date(invoice.invoiceDueDate)
      : new Date(invoiceDate.valueOf());

  if (invoice.invoiceDueDate === "") {
    invoiceDueDate.setDate(invoiceDueDate.getDate() + 30);
  }

  const handleChange = (name: keyof Invoice, value: string | number) => {
    if (name !== "products") {
      const newInvoice = { ...invoice };

      if (name === "logoWidth" && typeof value === "number") {
        newInvoice[name] = value;
      } else if (name !== "logoWidth" && typeof value === "string") {
        newInvoice[name] = value;
      }

      setInvoice(newInvoice);
    }
  };

  const handleProductChange = (
    index: number,
    name: keyof Product,
    value: string
  ) => {
    const products = invoice.products.map((product, i) => {
      if (i === index) {
        const newProduct = { ...product };
        if (name === "name") {
          newProduct[name] = value;
        } else if (name === "description") {
          newProduct[name] = value;
        } else {
          if (
            value[value.length - 1] === "." ||
            (value[value.length - 1] === "0" && value.includes("."))
          ) {
            newProduct[name] = value;
          } else {
            const n = parseFloat(value);

            newProduct[name] = (n ? n : 0).toString();
          }
        }

        return newProduct;
      }

      return { ...product };
    });

    setInvoice({ ...invoice, products });
  };

  const handleRemove = (i: number) => {
    const products = invoice.products.filter((product, index) => index !== i);

    setInvoice({ ...invoice, products });
  };

  const handleAdd = () => {
    const products = [...invoice.products, { ...initialProduct }];

    setInvoice({ ...invoice, products });
  };

  const calculateAmount = (quantity: string, rate: string) => {
    const quantityNumber = parseFloat(quantity);
    const rateNumber = parseFloat(rate);
    const amount =
      quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

    return amount.toFixed(2);
  };

  useEffect(() => {
    let subTotal = 0;

    invoice.products.forEach((product) => {
      const quantityNumber = parseFloat(product.quantity);
      const rateNumber = parseFloat(product.rate);
      const amount =
        quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

      subTotal += amount;
    });

    const shippingCosts = parseFloat(invoice.shippingCosts);
    subTotal += shippingCosts;
    setSubTotal(subTotal);
  }, [invoice.products, invoice.shippingCosts]);

  useEffect(() => {
    const match = invoice.taxLabel.match(/(\d+)%/);
    const taxRate = match ? parseFloat(match[1]) : 0;
    const saleTax = subTotal ? (subTotal * taxRate) / 100 : 0;

    setSaleTax(saleTax);
  }, [subTotal, invoice.taxLabel]);

  const onInvoiceUpdated = (invoice: Invoice) => {
    window.localStorage.setItem("invoiceData", JSON.stringify(invoice));
  };

  useEffect(() => {
    onInvoiceUpdated(invoice);
  }, [invoice]);

  document.body.style.overflow = "auto";
  document.body.style.background =
    "radial-gradient(50% 50% at 50% 0%, rgb(57, 23, 87) 0%, rgb(0, 0, 0) 100%)";

  return (
    <Document pdfMode={pdfMode}>
      <Page className="invoice-wrapper" pdfMode={pdfMode}>
        {!pdfMode && <Download data={invoice} />}

        <View className="flex mt-30" pdfMode={pdfMode}>
          <View className="w-50" pdfMode={pdfMode}>
            <EditableInput
              className="fs-45 left bold"
              placeholder="Invoice"
              value={invoice.title}
              onChange={(value) => handleChange("title", value)}
              pdfMode={pdfMode}
            />
            <View className="w-55" pdfMode={pdfMode}>
              <Text
                className="bold dark"
                style={{ marginBottom: "5px" }}
                pdfMode={pdfMode}
              >
                Bill To:
              </Text>
              {pdfMode && invoice.clientCompanyName === "" ? (
                <Text className="span" pdfMode={true}>
                  Your client's name
                </Text>
              ) : (
                <EditableInput
                  placeholder="Your client's name"
                  value={invoice.clientCompanyName}
                  onChange={(value) => handleChange("clientCompanyName", value)}
                  pdfMode={pdfMode}
                />
              )}
              {pdfMode && invoice.clientCompanyAddressLine1 === "" ? (
                <Text className="span" pdfMode={true}>
                  Client's address
                </Text>
              ) : (
                <EditableInput
                  placeholder="Client's address"
                  value={invoice.clientCompanyAddressLine1}
                  onChange={(value) =>
                    handleChange("clientCompanyAddressLine1", value)
                  }
                  pdfMode={pdfMode}
                />
              )}
              {pdfMode && invoice.clientCompanyAddressLine2 === "" ? (
                <Text className="span" pdfMode={true}>
                  City, ZIP zode
                </Text>
              ) : (
                <EditableInput
                  placeholder="City, ZIP zode"
                  value={invoice.clientCompanyAddressLine2}
                  onChange={(value) =>
                    handleChange("clientCompanyAddressLine2", value)
                  }
                  pdfMode={pdfMode}
                />
              )}
              <EditableSelect
                options={countryList}
                value={invoice.clientCompanyCountry}
                onChange={(value) =>
                  handleChange("clientCompanyCountry", value)
                }
                pdfMode={pdfMode}
              />
            </View>
          </View>
          <View className="w-50 right" pdfMode={pdfMode}>
            <EditableFileImage
              className="logo"
              placeholder="Upload your logo"
              value={invoice.logo}
              width={invoice.logoWidth}
              pdfMode={pdfMode}
              onChangeImage={(value) => handleChange("logo", value)}
              onChangeWidth={(value) => handleChange("logoWidth", value)}
            />
            {pdfMode && invoice.companyName === "" ? (
              <Text className="span" pdfMode={true}>
                Your company
              </Text>
            ) : (
              <EditableInput
                className="fs-20 bold right"
                placeholder="Your company"
                value={invoice.companyName}
                onChange={(value) => handleChange("companyName", value)}
                pdfMode={pdfMode}
              />
            )}
            {pdfMode && invoice.employeeName === "" ? (
              <Text className="span" pdfMode={true}>
                Your name
              </Text>
            ) : (
              <EditableInput
                className="right"
                placeholder="Your name"
                value={invoice.employeeName}
                onChange={(value) => handleChange("employeeName", value)}
                pdfMode={pdfMode}
              />
            )}
            {pdfMode && invoice.companyAddressLine1 === "" ? (
              <Text className="span" pdfMode={true}>
                Company's address
              </Text>
            ) : (
              <EditableInput
                className="right"
                placeholder="Company's address"
                value={invoice.companyAddressLine1}
                onChange={(value) => handleChange("companyAddressLine1", value)}
                pdfMode={pdfMode}
              />
            )}
            {pdfMode && invoice.companyAddressLine2 === "" ? (
              <Text className="span" pdfMode={true}>
                City, ZIP code
              </Text>
            ) : (
              <EditableInput
                className="right"
                placeholder="City, ZIP code"
                value={invoice.companyAddressLine2}
                onChange={(value) => handleChange("companyAddressLine2", value)}
                pdfMode={pdfMode}
              />
            )}
            <EditableSelect
              className="right"
              options={countryList}
              value={invoice.companyCountry}
              onChange={(value) => handleChange("companyCountry", value)}
              pdfMode={pdfMode}
            />
          </View>
        </View>

        <View className="flex mt-40" pdfMode={pdfMode}>
          <View className="w-50" pdfMode={pdfMode}>
            <View
              className="flex"
              style={{ marginBottom: "5px" }}
              pdfMode={pdfMode}
            >
              <View className="w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.invoiceNumberLabel}
                  onChange={(value) =>
                    handleChange("invoiceNumberLabel", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-40" pdfMode={pdfMode}>
                {pdfMode && invoice.invoiceNumber === "" ? (
                  <Text className="span" pdfMode={true}>
                    12345
                  </Text>
                ) : (
                  <EditableInput
                    placeholder="12345"
                    value={invoice.invoiceNumber}
                    onChange={(value) => handleChange("invoiceNumber", value)}
                    pdfMode={pdfMode}
                  />
                )}
              </View>
            </View>
            <View
              className="flex"
              style={{ marginBottom: "5px" }}
              pdfMode={pdfMode}
            >
              <View className="w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.referenceLabel}
                  onChange={(value) => handleChange("referenceLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-40" pdfMode={pdfMode}>
                {pdfMode && invoice.referenceNumber === "" ? (
                  <Text className="span" pdfMode={true}>
                    INV-123
                  </Text>
                ) : (
                  <EditableInput
                    placeholder="INV-123"
                    value={invoice.referenceNumber}
                    onChange={(value) => handleChange("referenceNumber", value)}
                    pdfMode={pdfMode}
                  />
                )}
              </View>
            </View>
          </View>
          <View className="w-50" pdfMode={pdfMode}>
            <View
              className="flex"
              style={{ marginBottom: "5px" }}
              pdfMode={pdfMode}
            >
              <View className="w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.invoiceDateLabel}
                  onChange={(value) => handleChange("invoiceDateLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-40" pdfMode={pdfMode}>
                <EditableCalendarInput
                  className="right"
                  value={format(invoiceDate, dateFormat)}
                  selected={invoiceDate}
                  onChange={(date) =>
                    handleChange(
                      "invoiceDate",
                      date && !Array.isArray(date)
                        ? format(date, dateFormat)
                        : ""
                    )
                  }
                  pdfMode={pdfMode}
                />
              </View>
            </View>
            <View
              className="flex"
              style={{ marginBottom: "5px" }}
              pdfMode={pdfMode}
            >
              <View className="w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.invoiceDueDateLabel}
                  onChange={(value) =>
                    handleChange("invoiceDueDateLabel", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-40" pdfMode={pdfMode}>
                <EditableCalendarInput
                  className="right"
                  value={format(invoiceDueDate, dateFormat)}
                  selected={invoiceDueDate}
                  onChange={(date) =>
                    handleChange(
                      "invoiceDueDate",
                      date && !Array.isArray(date)
                        ? format(date, dateFormat)
                        : ""
                    )
                  }
                  pdfMode={pdfMode}
                />
              </View>
            </View>
          </View>
        </View>

        <View
          className="rounded-lg border border-green-500 mt-30 flex"
          style={{ padding: "4px" }}
          pdfMode={pdfMode}
        >
          <View className="w-48 p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="dark bold"
              value={invoice.productDescriptionTitle}
              onChange={(value) =>
                handleChange("productDescriptionTitle", value)
              }
              pdfMode={pdfMode}
            />
          </View>
          <View className="w-17 p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="dark bold right"
              value={invoice.productQuantityTitle}
              onChange={(value) => handleChange("productQuantityTitle", value)}
              pdfMode={pdfMode}
            />
          </View>
          <View className="w-17 p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="dark bold right"
              value={invoice.productRateTitle}
              onChange={(value) => handleChange("productRateTitle", value)}
              pdfMode={pdfMode}
            />
          </View>
          <View className="w-18 p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="dark bold right"
              value={invoice.productAmountTitle}
              onChange={(value) => handleChange("productAmountTitle", value)}
              pdfMode={pdfMode}
            />
          </View>
        </View>

        {invoice.products.map((product, i) => {
          return pdfMode &&
            product.name === "" &&
            product.description === "" ? (
            <Text key={i}></Text>
          ) : (
            <View key={i} className="row flex" pdfMode={pdfMode}>
              <View className="w-48 p-4-8 pb-10" pdfMode={pdfMode}>
                <EditableInput
                  className="dark"
                  placeholder="Enter the item name"
                  value={product.name}
                  onChange={(value) => handleProductChange(i, "name", value)}
                  pdfMode={pdfMode}
                />
                <EditableTextarea
                  className="dark"
                  rows={1}
                  placeholder="Enter the item description"
                  value={product.description}
                  onChange={(value) =>
                    handleProductChange(i, "description", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-17 p-4-8 pb-10" pdfMode={pdfMode}>
                <EditableInput
                  className="dark right"
                  value={product.quantity}
                  onChange={(value) =>
                    handleProductChange(i, "quantity", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-17 p-4-8 pb-10" pdfMode={pdfMode}>
                <EditableInput
                  className="dark right"
                  value={product.rate}
                  onChange={(value) => handleProductChange(i, "rate", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="w-18 p-4-8 pb-10" pdfMode={pdfMode}>
                <Text className="dark right" pdfMode={pdfMode}>
                  {calculateAmount(product.quantity, product.rate)}
                </Text>
              </View>
              {!pdfMode && (
                <button
                  className="link row__remove"
                  aria-label="Remove service"
                  title="Remove service"
                  onClick={() => handleRemove(i)}
                >
                  <span className="icon icon-remove bg-red"></span>
                </button>
              )}
            </View>
          );
        })}
        <View className="w-50 mt-10" pdfMode={pdfMode}>
          {!pdfMode && (
            <button className="link" onClick={handleAdd}>
              <span className="icon icon-add bg-green mr-10"></span>
              Add an Item
            </button>
          )}
        </View>
        {pdfMode && invoice.notes === "" ? (
          <Text></Text>
        ) : (
          <View className="mt-30" pdfMode={pdfMode}>
            <Text className="left bold dark" pdfMode={pdfMode}>
              Notes
            </Text>
            <View className="pb-10 text-left w-full" pdfMode={pdfMode}>
              <EditableTextarea
                className="dark"
                rows={3}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit tincidunt pretium. Duis ante nisl, pulvinar posuere bibendum quis, tincidunt id tellus. Aliquam sodales tortor."
                value={invoice.notes}
                onChange={(value) => handleChange("notes", value)}
                pdfMode={pdfMode}
              />
            </View>
          </View>
        )}

        <View className="flex" pdfMode={pdfMode}>
          <View className="w-50 mt-10" pdfMode={pdfMode}>
            <Text></Text>
          </View>
          <View className="w-50 mt-20" pdfMode={pdfMode}>
            {pdfMode && parseInt(invoice.shippingCosts) === 0 ? (
              <Text></Text>
            ) : (
              <View className="flex" pdfMode={pdfMode}>
                <View
                  style={{ width: "50%", padding: "5px" }}
                  pdfMode={pdfMode}
                >
                  <EditableInput
                    value={invoice.shippingCostsLabel}
                    onChange={(value) =>
                      handleChange("shippingCostsLabel", value)
                    }
                    pdfMode={pdfMode}
                  />
                </View>
                <View
                  style={{ width: "50%", padding: "5px" }}
                  pdfMode={pdfMode}
                >
                  <EditableInput
                    className="right bold dark"
                    value={invoice.shippingCosts}
                    onChange={(value) => handleChange("shippingCosts", value)}
                    pdfMode={pdfMode}
                  />
                </View>
              </View>
            )}
            <View className="flex" pdfMode={pdfMode}>
              <View style={{ width: "50%", padding: "5px" }} pdfMode={pdfMode}>
                <EditableInput
                  value={invoice.subTotalLabel}
                  onChange={(value) => handleChange("subTotalLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View style={{ width: "50%", padding: "5px" }} pdfMode={pdfMode}>
                <Text className="right bold dark" pdfMode={pdfMode}>
                  {subTotal?.toFixed(2)}
                </Text>
              </View>
            </View>
            {pdfMode &&
            (invoice.taxLabel.match(/(\d+)%/) === null ||
              (invoice.taxLabel.match(/(\d+)%/) !== null &&
                parseFloat(invoice.taxLabel.match(/(\d+)%/)![1]) === null)) ? (
              <Text></Text>
            ) : (
              <View className="flex" pdfMode={pdfMode}>
                <View
                  style={{ width: "50%", padding: "5px" }}
                  pdfMode={pdfMode}
                >
                  <EditableInput
                    value={invoice.taxLabel}
                    onChange={(value) => handleChange("taxLabel", value)}
                    pdfMode={pdfMode}
                  />
                </View>
                <View
                  style={{ width: "50%", padding: "5px" }}
                  pdfMode={pdfMode}
                >
                  <Text className="right bold dark" pdfMode={pdfMode}>
                    {saleTax?.toFixed(2)}
                  </Text>
                </View>
              </View>
            )}
            <View
              className="flex bg-gray"
              style={{ padding: "5px" }}
              pdfMode={pdfMode}
            >
              <View style={{ width: "50%", padding: "5px" }} pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.totalLabel}
                  onChange={(value) => handleChange("totalLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="flex"
                style={{ width: "50%", padding: "5px" }}
                pdfMode={pdfMode}
              >
                <EditableInput
                  className="dark bold right ml-30"
                  value={invoice.currency}
                  onChange={(value) => handleChange("currency", value)}
                  pdfMode={pdfMode}
                />
                <Text className="right bold dark w-auto" pdfMode={pdfMode}>
                  {(typeof subTotal !== "undefined" &&
                  typeof saleTax !== "undefined"
                    ? subTotal + saleTax
                    : 0
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePage;
