import React, { FC, ReactNode } from "react";
import { Page as PdfPage } from "@react-pdf/renderer";
import compose from "../styles/compose";

interface Props {
  className?: string;
  pdfMode?: boolean;
  children: ReactNode;
}

const Page: FC<Props> = ({ className, pdfMode, children }) => {
  return (
    <>
      {pdfMode ? (
        <PdfPage
          size="A4"
          style={compose("page " + (className ? className : ""))}
        >
          {children}
        </PdfPage>
      ) : (
        <div
          style={{
            maxWidth: "700px",
            margin: "0 auto",
          }}
          className={"page " + (className ? className : "")}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Page;
