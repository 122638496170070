import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Navbar, Nav, NavItem } from "react-bootstrap";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Navbar
      expand="lg"
      className="justify-content-center justify-content-md-between justify-content-lg-between"
      style={{
        padding: "20px",
        fontSize: "15px",
        fontFamily: "Inter, sans-serif",
        fontWeight: "400",
        color: "#fff",
        maxWidth: "100%",
      }}
    >
      <NavItem>
        <Nav.Link
          href="mailto:hello@kubefinance.com"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.borderColor = "white";
            e.currentTarget.style.textDecoration = "underline";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.borderColor = "transparent";
            e.currentTarget.style.textDecoration = "none";
          }}
        >
          hello@kubefinance.com
        </Nav.Link>
      </NavItem>
      <NavItem
        style={{
          fontSize: "15px",
          fontFamily: "Inter, sans-serif",
          fontWeight: "400",
          color: "#fff",
        }}
      >
        © {currentYear} Kube Finance. All rights reserved.
      </NavItem>
      <NavItem>
        <a
          href="https://www.linkedin.com/company/kubefinance/?viewAsMember=true"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{
              fontSize: "24px",
              textDecoration: "none",
              color: "inherit",
            }}
          />
        </a>
        <span style={{ marginRight: "20px" }} />
        <a
          href="https://twitter.com/KubeFinance"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <FontAwesomeIcon
            icon={faTwitter}
            style={{
              fontSize: "24px",
              textDecoration: "none",
              color: "inherit",
            }}
          />
        </a>
      </NavItem>
    </Navbar>
  );
};

export default Footer;
